/* @media (min-width: 960px) {
  .css-ys4nsp {
    margin-top: 2.9em;
  }

  .css-47knv4 {
    margin-top: -3.2em;
    margin-right: -3em;
    margin-left: 0em;
  }
}

@media (min-width: 1536px) {
  .css-47knv4 {
    margin-top: -2.8em;
  }
} */

.ProductListPrice-discountPrice {
  color: red !important;
  text-decoration: line-through !important;
}

.loader {
  width: 1.4rem;
  margin: 0 1rem 0 0.5rem;
  height: 1.4rem;
  border: 3px solid rgb(102, 43, 87);
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
